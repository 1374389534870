import "dotenv/config";

import Web3 from "web3";
import useDidMountEffect from "./useDidMount";
import { useEffect, useState } from "react";
import { interval, map, Subject, takeUntil, tap } from "rxjs";

/**
 * Gets contract info
 * @param {addr} address The contract address
 * @param {json} abi JSON abi
 * @returns connection,
    loading,
    tokenID,
    amount,
    endTime,
    highestBidder,
    minBidIncrement,
    bidding,
    creatingAuction,
    bid,
    nextAuction,
    auctionFinished
 */
const useContract = (address, abi) => {
  const CONTRACT_ADDR = process.env.REACT_APP_CONTRACT_ADDRESS;
  const INFURA_ID = process.env.REACT_APP_INFURA_ID;
  const INFURA_URL =
    process.env.REACT_APP_IS_TESTNET === "true"
      ? "wss://rinkeby.infura.io/ws/v3/"
      : "wss://mainnet.infura.io/ws/v3/";
  const [windowContract, setWindowContract] = useState(null);
  const [infuraContract, setInfuraContract] = useState(null);
  const [connection, setConnection] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tokenID, setTokenID] = useState(undefined);
  const [amount, setAmount] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [highestBidder, setHighestBidder] = useState("");
  const [minBidIncrement, setMinBidIncrement] = useState(0);
  const [bidding, setBidding] = useState(false);
  const [creatingAuction, setCreatingAuction] = useState(false);
  const [timeNow, setTimeNow] = useState(0);
  const [auctionFinished, setAuctionFinished] = useState(false);

  const subscription = interval(1000).subscribe(() =>
    setTimeNow(Math.floor(Date.now() / 1000))
  );

  // mount
  useEffect(() => {
    const ww3 = new Web3(window.ethereum);
    const wctx = new ww3.eth.Contract(abi, CONTRACT_ADDR);
    setWindowContract(wctx);

    const provider = new Web3.providers.WebsocketProvider(
      `${INFURA_URL}${INFURA_ID}`
    );
    const iw3 = new Web3(provider);
    const ictx = new iw3.eth.Contract(abi, CONTRACT_ADDR);
    setInfuraContract(ictx);

    console.log(INFURA_URL);

    return () => subscription.unsubscribe();
  }, []);

  useDidMountEffect(() => {
    setAuctionFinished(+timeNow > +endTime);
  }, [timeNow]);

  // GET
  useDidMountEffect(() => {
    if (!infuraContract) {
      return;
    }

    setLoading(true);
    infuraContract.methods
      .auction()
      .call()
      .then((val) => {
        setTokenID(val.tokenID);
        setAmount(val.amount);
        setEndTime(+val.endTime);
        setHighestBidder(val.bidder);
      });

    infuraContract.methods
      .minBidIncrementPercentage()
      .call()
      .then((val) => {
        setMinBidIncrement(val);
      });

    setLoading(false);
  }, [infuraContract]);

  // Socket
  useDidMountEffect(() => {
    if (!infuraContract) {
      return;
    }
    infuraContract.events
      .allEvents()
      .on("connected", (id) => {
        setConnection(true);
      })
      .on("data", ({ event, returnValues }) => {
        switch (event) {
          case "AuctionCreated":
            setTokenID(returnValues.furbId);
            setEndTime(returnValues.endTime);
            break;
          case "AuctionBid":
            setHighestBidder(returnValues.sender);
            setAmount(returnValues.value);
            break;
          case "AuctionExtended":
            // console.log(returnValues);
            setEndTime(returnValues.endTime);
            break;
          default:
            break;
        }
      })
      .on("error", (err) => {
        setConnection(false);
      });
  }, [infuraContract]);

  /**
   * Bids on an auction
   * @param {addr} bidder Wallet address
   * @param {int} amount Price (wei)
   * @param {int} furbId Furb ID
   */
  const bid = (bidder, amount, furbId) => {
    setBidding(true);
    windowContract.methods
      .createBid(furbId)
      .send({ from: bidder, value: amount })
      .on("receipt", () => {
        setBidding(false);
      })
      .on("error", (err) => {
        setBidding(false);
        console.warn(err);
      });
  };

  const nextAuction = (addr) => {
    setCreatingAuction(true);
    windowContract.methods
      .settleCurrentAndCreateNewAuction()
      .send({ from: addr })
      .on("receipt", () => {
        setCreatingAuction(false);
        // window.location.reload(false);
      })
      .on("error", (err) => {
        setCreatingAuction(false);
        console.warn(err);
      });
  };

  return {
    connection,
    loading,
    tokenID,
    amount,
    endTime,
    highestBidder,
    minBidIncrement,
    bidding,
    creatingAuction,
    bid,
    nextAuction,
    auctionFinished,
  };
};

export default useContract;
