import { useEffect, useState } from "react";
import { utils } from "web3";
import { Transition } from "@headlessui/react";
import Countdown from "react-countdown";
import "./App.css";
import abi from "./assets/abi.json";
import logo from "./assets/images/logo.svg";
import useContract from "./hooks/useContract";
import useWallet from "./hooks/useWallet";
import axios from "axios";

const randomBall = `/ballz/${Math.floor(Math.random() * 305)}.png`;
const randomBall2 = `/ballz/${Math.floor(Math.random() * 305)}.png`;

function toFixed(num, fixed) {
  var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  return num.toString().match(re)[0];
}

const App = () => {
  const ball = randomBall;
  const ball2 = randomBall2;
  const { connect, address, slicedAddress } = useWallet();
  const {
    tokenID,
    amount,
    endTime,
    highestBidder,
    minBidIncrement,
    bidding,
    creatingAuction,
    bid,
    nextAuction,
    auctionFinished,
  } = useContract(address, abi);
  const [currentlyAuctioningImage, setCurrentlyAuctioningImage] = useState(null);
  const [bidInput, setBidInput] = useState("");

  useEffect(async () => {
    if (isNaN(tokenID)) {
      return;
    }
    axios
      .get(`${process.env.REACT_APP_METADATA_URL}${tokenID}`)
      .then((res) => res.data)
      .then(({ image }) => setCurrentlyAuctioningImage(image));
  }, [tokenID, currentlyAuctioningImage]);

  return (
    <>
      <div className="flex flex-col h-screen w-full">
        <div className="px-3 sm:px-6 pt-3">
          <h1 className="sr-only">DINGLEBERRIES</h1>

          <img className="w-full" src={logo} />
          <div className="flex w-6/6 mt-6 justify-center sm:mt-12 mb-4 sm:mb-5">
            <div className="bg-black text-white p-3">
              {address ? (
                <span>{slicedAddress}</span>
              ) : (
                <button onClick={() => connect()} className="bg-black text-white rounded-none text-xl md:text-4xl">
                  Connect Wallet
                </button>
              )}
            </div>
          </div>
        </div>
        <Transition
          show={!!currentlyAuctioningImage}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
        >
          <section className="px-3 sm:px-6 flex flex-col items-center lg:items-start lg:flex-row justify-center lg:space-x-12">
            <div className="aspect-square w-full lg:w-1/3">
              <img src={currentlyAuctioningImage} />
            </div>
            <div className="lg:w-1/3 flex flex-col justify-end pb-5">
              <span className="text-4xl lg:text-6xl">
                {new Date().toLocaleString("default", {
                  month: "long",
                  day: "2-digit",
                  year: "numeric",
                })}
              </span>
              <span className="text-5xl lg:text-8xl mb-3">Dingleberries #{tokenID}</span>
              <div className="flex space-x-8 mb-6 text-2xl">
                <div className="flex flex-col">
                  <span>{auctionFinished ? "Winning bid" : "Current bid"}</span>
                  <span>Ξ {toFixed(+utils.fromWei(amount.toString(), "ether"), 4)}</span>
                  <div>
                    By: {`${highestBidder.slice(0, 5)}...${highestBidder.slice(highestBidder.length - 4)}`}
                  </div>
                </div>
                <div className="border-r border-gray-900"></div>
                <div className="flex flex-col">
                  <span>Ends in</span>
                  <Countdown date={endTime * 1000} key={endTime * 1000} dateInHours={true} />
                </div>
              </div>
              {!address ? (
                <div>Connect wallet to place a bid</div>
              ) : auctionFinished ? (
                <div className="flex justify-center lg:justify-start">
                  <button
                    className="text-center p-2 text-white bg-black grow sm:flex-shrink"
                    disabled={creatingAuction}
                    onClick={() => nextAuction(address)}
                  >
                    {address.toLowerCase() === highestBidder.toLowerCase()
                      ? "Claim your NFT"
                      : "Start next auction"}
                  </button>
                  <div className=" hidden sm:grow"></div>
                </div>
              ) : (
                <form
                  onSubmit={(e) => {
                    bid(address, utils.toWei(bidInput), tokenID);
                    setBidInput("");
                    e.preventDefault();
                  }}
                  className="flex items-end font-sans space-x-2"
                >
                  <div>
                    <span className="text-lg">
                      Minimum bid:{" "}
                      {toFixed(+utils.fromWei((amount * (minBidIncrement / 100 + 1)).toString(), "ether"), 4)} ETH
                    </span>
                    <div className="flex grow items-center space-x-1 py-2 border border-black px-4">
                      <input
                        value={bidInput}
                        disabled={!address || bidding}
                        onChange={(e) => setBidInput(e.target.value)}
                        className="grow border-none focus:outline-none appearance-none"
                      />
                      <span>ETH</span>
                    </div>
                  </div>
                  <button
                    disabled={!address || bidding}
                    type="submit"
                    className="p-2 text-white bg-black border border-black flex-shrink-0"
                  >
                    Place bid
                  </button>
                </form>
              )}
            </div>
          </section>
        </Transition>
        <div className="text-center px-6 sm:w-7/12 text-2xl sm:text-4xl self-center mt-5 mb-20">
          <p className="mt-8">
            ATTENTION: if you minted in the old contract, send your old FURBZ to Ben.eth to receive corresponding
            from new contract. Thank you.
          </p>
          <p className="mt-8">
            DINGLEBERRIES are a collection of {tokenID ? tokenID - 1 : "?"} little balls of good karma. 1
            DINGLEBERRY is auctioned per day in a nounsDAO style format
          </p>
          <p className="mt-8">Created by PFPMAFIA.</p>
        </div>
        <div className="text-center md:w-7/12 self-center mt-20 px-6">
          <h2 className="text-6xl sm:text-9xl mb-5 sm:mb-10">ROADMAP</h2>
          <ul>
            <li className="text-3xl py-2">make stickers</li>
          </ul>
        </div>

        <div className="flex flex-col mt-20 px-6">
          <h2 className="text-center text-6xl sm:text-9xl mb-5 sm:mb-10">LICENSING</h2>
          <div className="self-center w-10/12 sm:w-8/12 md:w-6/12 lg:w-5/12 text-2xl">
            <p className="text-center">CC0 bitch</p>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <img className="w-5/12 mb-6" src={ball2} />
          <img className="w-2/12" src={logo} />
        </div>
        <footer className="mt-12 mb-12 sm:w-2/3 text-xl sm:mx-auto text-center">
          <div className="mb-16 flex justify-around w-10/12 md:w-8/12 mx-auto">
            <a href="https://twitter.com/berry6_dingle" className="mr-6" target="_blank">
              TWITTER
            </a>
            <a href="https://discord.gg/57RFGQDW7N" target="_blank">
              DISCORD
            </a>
          </div>
        </footer>
      </div>
    </>
  );
};

export default App;
